import { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core";
import useTimer from "../../hooks/useTimer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "./styles";

import MuteAudioButton from "../MuteAudioButton";
import MuteVideoButton from "../MuteVideoButton";
import RecordingButton from "../RecordingButton";
import LayoutButton from "../LayoutButton";
import MuteAll from "../MuteAllButton";
import ScreenSharingButton from "../ScreenSharingButton";
import EndCallButton from "../EndCallButton";
import MoreOptionsButton from "../MoreOptionsButton";
import PictureInPictureButton from "../PictureInPictureButton";

import PeopleIcon from "@material-ui/icons/People";

const isPipEnabled = process.env.REACT_APP_PIP_ENABLED === 'true';
const patientId = window.localStorage.getItem("patientId");

export default function ToolBar({
  room,
  connected,
  cameraPublishing,
  isScreenSharing,
  startScreenSharing,
  stopScreenSharing,
  participants,
  localParticipant,
  togglePIP
}) {
  const theme = useTheme();
  const { timer, handleStart } = useTimer(0);

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    return ` ${getMinutes}:${getSeconds}`;
  };

  const classes = styles();

  const [hasAudio, setHasAudio] = useState(true);
  const [hasVideo, setHasVideo] = useState(true);
  const [areAllMuted, setAllMuted] = useState(false);
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));

  const handleMuteAll = () => {
    if (!areAllMuted) {
      participants.map((participant) => participant.camera.disableAudio());

      setAllMuted(true);
    } else {
      participants.map((participant) => participant.camera.enableAudio());
      setAllMuted(false);
    }
  };

  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };

  const toggleAudio = () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
      }
    }
  };

  const getVideoSource = () => {
    if (room && room.camera) {
      return room.camera.getVideoDevice();
    }
  };

  const changeVideoSource = (videoId) => room.camera.setVideoDevice(videoId);

  const changeAudioSource = (audioId) => room.camera.setAudioDevice(audioId);

  const getAudioSource = async () => {
    if (room && room.camera) {
      const audioDevice = await room.camera.getAudioDevice();
      return audioDevice.deviceId;
    }
  };

  const endCall = () => {
    if (room) {
      room.leave();
      window.top?.postMessage('ROFIM_VISIO_END_REQUESTED', '*');
      window.open("about:blank", "_self");
      window.close();
    }
  };

  useEffect(() => {
    if (connected) {
      const isAudioEnabled =
        room && room.camera && room.camera.isAudioEnabled() ? true : false;
      const isVideoEnabled =
        room && room.camera && room.camera.isVideoEnabled() ? true : false;
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
  }, [connected, room]);

  useEffect(() => {
    handleStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isMobileWidth ? (
    <div className={classes.toolbarMobileContainer}>
      <MuteAudioButton
        toggleAudio={toggleAudio}
        hasAudio={hasAudio}
        classes={classes}
        changeAudioSource={changeAudioSource}
        localParticipant={localParticipant}
      />
      <EndCallButton classes={classes} handleEndCall={endCall} />
      <MuteVideoButton
        toggleVideo={toggleVideo}
        hasVideo={hasVideo}
        classes={classes}
        changeVideoSource={changeVideoSource}
      />
    </div>
  ) : (
    <div className={classes.toolbarContainer}>
      <div className={classes.leftSide}>
        <RecordingButton room={room} classes={classes} />
        <p className={classes.timerText}>{formatTime(timer)}</p>
        <span className={classes.timerText}>|</span>
        <div className={classes.centerWithSpacing}>
          <PeopleIcon />
          <p className={classes.centerWithSpacing}>{participants.length + 1}</p>
        </div>
      </div>
      <div className={classes.center}>
        <ScreenSharingButton
          isScreenSharing={isScreenSharing}
          startScreenSharing={startScreenSharing}
          stopScreenSharing={stopScreenSharing}
          classes={classes}
        />
        <MuteAudioButton
          toggleAudio={toggleAudio}
          hasAudio={hasAudio}
          classes={classes}
          changeAudioSource={changeAudioSource}
          getAudioSource={getAudioSource}
          cameraPublishing={cameraPublishing}
          localParticipant={localParticipant}
        />
        <EndCallButton classes={classes} handleEndCall={endCall} />
        <MuteVideoButton
          toggleVideo={toggleVideo}
          hasVideo={hasVideo}
          classes={classes}
          getVideoSource={getVideoSource}
          cameraPublishing={cameraPublishing}
          changeVideoSource={changeVideoSource}
        />
        <MuteAll
          handleMuteAll={handleMuteAll}
          areAllMuted={areAllMuted}
          classes={classes}
        />
      </div>
      <div className={classes.rightSide}>
        {isPipEnabled && patientId && <PictureInPictureButton classes={classes} togglePIP={togglePIP} room={room} />}
        <LayoutButton classes={classes} room={room} />
        <MoreOptionsButton
          classes={classes}
          participants={participants}
          room={room}
          localParticipant={localParticipant}
        />
      </div>
    </div>
  );
}
